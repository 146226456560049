import { cloneDeep } from "lodash";

export default {
  setOffers: (state, payload) => {
    state.offers = cloneDeep(payload);
  },
  emptyOffers: (state, payload) => {
    state.offers = payload;
  },
  updateTender(state, newTender) {
    const index = state.offers.findIndex(
      (tender) => tender.tenderId === newTender.tenderId,
    );
    if (index !== -1) {
      state.offers[index] = { ...state.offers[index], ...newTender };
    }
  },
  setOfferedOffers(state, offers) {
    state.offeredOffers = offers;
  },
};
