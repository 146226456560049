import router from "@/router";
import tenderService from "@/services/tenderService";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useStore } from "vuex";
import { TenderState } from "@/custom-types/types";
import { getTenders } from "@/utilities/offerUtilities";

export default {
  fetchOffers: async ({ commit }, supplierId) => {
    return tenderService
      .fetchTenderOffersBySupplier(supplierId)
      .then((response) => {
        const data = response.data;
        if (data) {
          commit("setOffers", data);
        }
      })
      .catch(() => {
        router.push("/error").catch(() => {});
      });
  },
  emptyOffers: async ({ commit }) => {
    commit("emptyOffers", null);
  },
  updateTender: async ({ commit }, tender) => {
    commit("updateTender", tender);
  },
  async fetchOfferedOffers({ commit, state }) {
    const tenders = getTenders(state, [TenderState.Offered]);
    const { getTender } = useTenderApi();

    const store = useStore();
    const supplier = store.getters["supplier/getSupplier"];
    const supplierId = supplier.id;

    const tendersWithDetails = await Promise.all(
      tenders.map(async (tender) => {
        try {
          const response = await getTender({
            supplierId: supplierId,
            tenderId: tender.tenders[0].tenderId,
          });
          const updatedTender = response.data;

          const {
            tenderId,
            createdOn,
            tenderState,
            customerOrganizationId,
            externalTenderId,
            integrationSource,
            integrationExternalId,
            validThrough,
            nickname,
            deleted,
            customerOrganization,
            priceRequests,
            supplierPortfolios,
          } = updatedTender;

          const formattedTender = {
            tenderId,
            createdOn,
            tenderState,
            customerOrganizationId,
            externalTenderId,
            integrationSource,
            integrationExternalId,
            validThrough,
            nickname,
            deleted,
            customerOrganizationName: customerOrganization?.name,
            priceRequests: priceRequests.map((priceRequest) => ({
              ...priceRequest,
              customerPropertyAddress:
                priceRequest.customerPropertyDto?.address?.fullAddress || null,
            })),
            supplierPortfolios,
          };

          return {
            tenders: [formattedTender],
            address: tender.address,
          };
        } catch (error) {
          console.error("Error fetching tender details:", error);
          return tender;
        }
      }),
    );

    commit("setOfferedOffers", tendersWithDetails);
  },
};
