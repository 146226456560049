<template>
  <base-modal
    v-model="showModal"
    centered
    size="lg"
    hide-header
    modal-class="profile-modal"
    scrollable
    @hide="closeModal"
  >
    <template #default>
      <div class="profile-modal__body-content">
        <div class="profile-modal__title-content">
          <div class="profile-modal__title">{{ title }}</div>
          <div class="profile-modal__subtitle">{{ subtitle }}</div>
        </div>
        <div v-if="localServiceTypes" class="profile-modal__info-tag-container">
          <div v-for="(serviceType, index) in localServiceTypes" :key="index">
            <div
              v-if="serviceType.categories.length"
              class="profile-modal__section-title"
            >
              {{ serviceType?.title }}
            </div>
            <div class="profile-modal__info-tag-group">
              <ServiceCardCategory
                v-for="(category, categoryIndex) in serviceType.categories"
                :key="categoryIndex"
                v-model="category.selected"
                :title="category.title"
                :icon-src="category.icon"
                @update:model-value="
                  updateCategorySelected(index, categoryIndex, $event)
                "
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div v-if="hasSelectedCategories" class="profile-modal__summary-services">
        <div class="profile-modal__summary-services--title">
          Valgte tjenester
        </div>
        <div class="profile-modal__summary-services--container">
          <InfoTag
            v-for="category in categoriesSelected"
            :key="category.serviceCategoryId"
            :title="category.title"
            :subtitle="''"
            :icon-src="category.icon"
            :show-remove-button="true"
            :close-icon-src="closeIconSrc"
            @remove="removeSelectedCategory(category)"
          />
        </div>
      </div>
      <div class="profile-modal__footer-buttons">
        <ProfileButton :loading="isSaving" @click="save">{{
          saveButtonText
        }}</ProfileButton>
        <ProfileButton :is-cancel="true" @click="cancel">{{
          cancelButtonText
        }}</ProfileButton>
        <slot name="footer" />
      </div>
    </template>
  </base-modal>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, watch } from "vue";
import BaseModal from "@/components/Base/BaseModal/BaseModal.vue";
import ProfileButton from "./ProfileButton.vue";
import ServiceCardCategory from "./ServiceCardCategory.vue";
import InfoTag from "./InfoTag.vue";
import { ServiceCategory } from "@/stores/supplier/types";

interface CategoryWithIconAndDescription extends ServiceCategory {
  title: string;
  icon: string;
  description: string;
}

interface ServiceType {
  title: string;
  categories: CategoryWithIconAndDescription[];
}

const props = defineProps({
  modelValue: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  title: {
    type: String as PropType<string>,
    required: true,
  },
  subtitle: {
    type: String as PropType<string>,
    required: true,
  },
  iconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  saveButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  cancelButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  closeIconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  serviceTypes: {
    type: Array as PropType<ServiceType[]>,
    required: true,
  },
  isSaving: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "save"]);

const showModal = computed({
  get: () => props.modelValue,
  set: (newVal) => emit("update:modelValue", newVal),
});

const localServiceTypes = ref<ServiceType[]>([]);

const resetLocalServiceTypes = () => {
  localServiceTypes.value = props.serviceTypes.map((serviceType) => {
    return {
      ...serviceType,
      categories: serviceType.categories
        .map((category) => ({
          ...category,
          selected: category.selected,
        }))
        .sort((a, b) => a.title.localeCompare(b.title)),
    };
  });
};

watch(
  () => props.serviceTypes,
  () => {
    resetLocalServiceTypes();
  },
  { immediate: true, deep: true },
);

watch(showModal, (newVal) => {
  if (!newVal) {
    resetLocalServiceTypes();
  }
});

const categoriesSelected = computed(() =>
  localServiceTypes.value
    .map((serviceType) =>
      serviceType.categories.filter((category) => category.selected),
    )
    .flat(),
);

const hasSelectedCategories = computed(
  () => categoriesSelected.value.length > 0,
);

const updateCategorySelected = (
  serviceTypeIndex: number,
  categoryIndex: number,
  selected: boolean,
) => {
  localServiceTypes.value[serviceTypeIndex].categories[categoryIndex].selected =
    selected;
};

const removeSelectedCategory = (category: CategoryWithIconAndDescription) => {
  category.selected = false;
};

const closeModal = () => {
  showModal.value = false;
};

const save = () => {
  const selectedCategories = categoriesSelected.value.map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ description, icon, title, ...rest }) => rest,
  );
  emit("save", selectedCategories);
};

const cancel = () => {
  closeModal();
};
</script>

<style lang="scss">
.profile-modal {
  .modal-dialog {
    display: flex;
    height: calc(100% - 10rem);
    min-width: 1100px;
    border-radius: 8px;
    background: var(--White, #fff);
  }

  .modal-content {
    border-radius: 8px;
    border: none;
    padding: 64px 64px 0px 64px;
  }

  .modal-body {
    background-color: #fff;
    padding: 0;
  }

  .modal-footer {
    border: none;
    padding: 0;
    margin-top: 0;
    justify-content: flex-start;
  }
}
</style>

<style lang="scss" scoped>
.profile-modal {
  &__body-content {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }

  &__close-button-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0 2rem;
  }

  &__main-content {
    max-width: 912px;
    margin: 0 auto;
  }

  &__title-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    align-self: start;
    align-items: flex-start;
  }

  &__title {
    color: var(--Dark-Purple, #441b49);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  &__subtitle {
    color: var(--Dark-Purple, #441b49);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__close-button {
    border: none;
    background: transparent;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }

  &__section {
    margin-top: 2rem;
  }

  &__section-title {
    color: var(--Dark-Purple, #441b49);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }

  &__summary-services {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    &--title {
      color: #441b49;
      font-size: 14px;
      font-family: Montserrat;
      font-weight: 600;
      word-wrap: break-word;
    }

    &--container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;
      padding: 12px 0;
      max-width: 100vw;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__info-tag-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    gap: 1rem;
  }

  &__info-tag-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__footer-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 1rem;
    padding: 1rem;
    border-top: 1px solid var(--Black, #dee2e6);
  }
}
</style>
