import {
  getTendersFromStore,
  mapToAddressTenders,
  createActiveTenders,
} from "@/utilities/offerUtilities";
import { RequestState } from "@/custom-types/GeneralTypes";
import { cloneDeep } from "lodash";

const filterTendersBySupplierAndStates = (tenders, supplierId, states) => {
  return tenders.filter((tender) =>
    tender.priceRequests.some((priceRequest) =>
      priceRequest.supplierRequests.some(
        (supplierRequest) =>
          supplierRequest.supplierId === supplierId &&
          states.includes(supplierRequest.requestState),
      ),
    ),
  );
};

// Getters
export default {
  getAllTenders: (state) => state.offers,

  getRequestedOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    const tenders = cloneDeep(getTendersFromStore(state));
    const filteredTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [
        RequestState.PRICE_REQUESTED,
        RequestState.INFORMATION_REQUESTED,
        RequestState.LOST,
        RequestState.UNANSWERED,
      ],
    );
    return mapToAddressTenders(filteredTenders);
  },

  getOfferedOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    console.log("supplierId", supplierId);
    const tenders = cloneDeep(getTendersFromStore(state));
    const filteredTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [RequestState.OFFERED],
    );
    return mapToAddressTenders(filteredTenders);
  },
  getActiveOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    const tenders = cloneDeep(getTendersFromStore(state));
    const activeTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [RequestState.ACCEPTED],
    );
    return mapToAddressTenders(createActiveTenders(activeTenders));
  },

  getTerminatedOrArchivedOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    console.log("supplierId", supplierId);
    const tenders = cloneDeep(getTendersFromStore(state));

    const terminatedOrArchivedTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [
        RequestState.COMPLETED,
        RequestState.REJECTED,
        RequestState.LOST,
        RequestState.ARCHIVED,
      ],
    );

    return mapToAddressTenders(terminatedOrArchivedTenders);
  },
};
